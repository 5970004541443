import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'listing',
    loadChildren: () => import('./components/car-list/car-list.module').then(m => m.ListingGridModule)
    //canActivate:[AuthGuard]
  },
  {
    path: 'car-detail/:id',
    loadChildren: () => import('./components/car-details/car-details.module').then(m => m.ListingDetailsModule)
    //canActivate:[AuthGuard]
  },

  {
    path: 'authentication',
    loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  { path: 'style-guide', loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule) },
  {
    path:'**',
    redirectTo:'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}